import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Validate",
    component: () => import("@/view/validate.vue")
  },
  {
    path: "/form",
    name: "Form",
    component: () => import("@/view/w9-form/form.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import('@/view/errors/unauthorized.vue')
  },
  {
    path: "/server-error",
    name: "ServerError",
    component: () => import('@/view/errors/500.vue')
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
