import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tosId: null,
    pin: '',
    csrf: ''
  },
  getters: {
    tosId: state => state.tosId,
    pin: state => state.pin,
    csrf: state => state.csrf
  },
  mutations: {
    save_tos_id (state, data) {
      state.tosId = data
    },
    save_member_pin (state, data) {
      state.pin = data
    },
    save_csrf (state, data) {
      state.csrf = data
    }
  },
  actions: {
    saveTosId ({commit}, data) {
      commit('save_tos_id', data)
    },
    savePin  ({commit}, data) {
      commit('save_member_pin', data)
    },
    saveCsrf ({commit}, csrf) {
      commit('save_csrf', csrf)
    }
  }
});
